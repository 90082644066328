<template>
  <div id="setting" class="content">
    <div class="box-show-title">
      <div class="container">
        <el-row :gutter="30">
          <el-col :span="24" :md="6">
            <div class="tab-setting">
              ตั้งค่าบัญชี
              <ul class="tab-site">
                <li>
                  <router-link to="/setting/myAccount">บัญชีของฉัน</router-link>
                </li>
                <li>
                  <router-link to="/setting/settingpassword"
                    >เปลี่ยนรหัสผ่านใหม่</router-link
                  >
                </li>
              </ul>
            </div>
          </el-col>
          <el-col :span="24" :md="18" class="detail-profile">
            <div class="grid-content">
              <el-row :gutter="30" class="setrow">
                <el-col :span="24" class="aboutus">
                  <p>ข้อมูลของฉัน</p>
                </el-col>
                <el-col :span="24" :md="17" class="box-tab-l">
                  <span class="account-type">ประเภทบัญชี</span>
                  <span class="font-light" v-if="user.role == 0"
                    >ผู้ใช้งานทั่วไป</span
                  >
                  <span class="font-light" v-if="user.role == 1">นักวิจัย</span>
                  <span class="font-light" v-if="user.role == 2">นักลงทุน</span>
                  <span class="font-light" v-if="user.role == 50">แอดมิน</span>

                  <el-form
                    ref="formPersonalInfo"
                    :label-position="'top'"
                    :model="formPersonalInfo"
                    :rules="rules"
                  >
                    <el-row :gutter="15">
                      <el-col :span="24">
                        <el-form-item label="Email">
                          <el-input
                            v-model="formPersonalInfo.email"
                            :disabled="true"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24" :sm="10" :md="6">
                        <el-form-item label="เพศ" prop="gender">
                          <el-select
                            v-model="formPersonalInfo.gender"
                            placeholder="กรุณาเลือกเพศ"
                          >
                            <el-option
                              v-for="item in dataGender"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24" :sm="14" :md="18">
                        <el-form-item label="ชื่อ-นามสกุล" prop="name">
                          <el-input v-model="formPersonalInfo.name"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item
                          label="หมายเลขโทรศัพท์"
                        >
                          <el-input
                            v-model="formPersonalInfo.mobilePhone"
                            maxlength="10"
                            v-mask="'##########'"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item label="วัน/เดือน/ปีเกิด">
                          <el-date-picker
                            v-model="formPersonalInfo.birthday"
                            type="date"
                            :picker-options="pickerOptions"
                            placeholder="กรุณาเลือก วัน/เดือน/ปีเกิด"
                          ></el-date-picker>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!-- -->
                  </el-form>
                </el-col>
                <el-col :span="24" :md="7" class="box-tab-r">
                  <el-upload
                    action="#"
                    class="box-image-upload box-profile text-center"
                    ref="upload"
                    list-type="picture-card"
                    :auto-upload="false"
                    :on-change="uploadImage"
                    :show-file-list="false"
                  >
                    <img
                      v-if="showDefault == false"
                      src="../assets/image/user.png"
                      class="showavatar 1"
                    />
                    <img
                      v-else-if="typeof image.raw == 'undefined'"
                      :src="callImage + user._id + '/' + user.profileImg"
                      class="showavatar 2"
                    />
                    <img v-else :src="getUrlImage(image)" class="showavatar" />

                    <div slot="default">
                      <p class="selectImg">เลือกรูป</p>
                    </div>
                  </el-upload>
                  <p class="size-img">
                    ขนาดไฟล์: สูงสุด 4 MB
                    <br />ไฟล์ที่รองรับ: .JPEG, .PNG
                  </p>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <div class="setbtn">
            <router-link to="/home" v-if="user == null || user.role != 50">
              <button class="btn btn-color-dark btn-cancle">
                <span>ยกเลิก</span>
              </button>
            </router-link>
            <router-link to="/management" v-else>
              <button class="btn btn-color-dark btn-cancle">
                <span>ยกเลิก</span>
              </button>
            </router-link>
            <button class="btn btn-color-dark btn-save" @click="updateUser()">
              <span>บันทึก</span>
            </button>
          </div>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/service/axios.js";
export default {
  computed: {
    user() {
      return this.$store.state.user;
    },
    token() {
      return this.$store.state.token;
    },
  },
  mounted() {
    if (this.user.email != "") {
      this.formPersonalInfo.email = this.user.email;
    }
    if (this.user.name != undefined) {
      this.formPersonalInfo.name = this.user.name;
    }
    if (this.user.gender != undefined) {
      this.formPersonalInfo.gender = this.user.gender;
    }
    if (this.user.mobilePhone != undefined) {
      this.formPersonalInfo.mobilePhone = this.user.mobilePhone;
    }
    if (this.user.birthday != undefined) {
      this.formPersonalInfo.birthday = this.user.birthday;
    }
    if (this.user.profileImg != undefined) {
      this.showDefault = true;
      console.log(this.showDefault);
      // this.image = this.user.profileImg;
    }
  },
  data() {
    return {
      callImage: process.env.VUE_APP_BASE_URL_IMAGE,
      showDefault: false,
      showProfile: true,
      image: "",
      imageUrl: "",
      dataGender: [
        {
          value: 1,
          label: "ชาย",
        },
        {
          value: 2,
          label: "หญิง",
        },
      ],
      formPersonalInfo: {
        email: "",
        gender: "",
        name: "",
        mobilePhone: null,
        birthday: "",
        profileImg: "",
        delFile: "",
      },
      rules: {
        gender: [{ required: true, message: "กรุณาเลือกเพศ", trigger: "blur" }],
        name: [
          { required: true, message: "กรุณากรอกชื่อ-นามสกุล", trigger: "blur" },
        ],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  methods: {
    getuser() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`user`)
        .then((res) => {
          if (res.data.success) {
            this.$store.commit("SET_USER", res.data.obj);
            console.log(res);
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("getuser error", e);
        });
    },
    updateUser() {
      this.$refs["formPersonalInfo"].validate((valid) => {
        if (valid) {
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
          var formData = new FormData();
          formData.append("email", this.formPersonalInfo.email);
          formData.append("gender", this.formPersonalInfo.gender);
          formData.append("name", this.formPersonalInfo.name);
          formData.append("mobilePhone", this.formPersonalInfo.mobilePhone);
          formData.append("birthday", this.formPersonalInfo.birthday);
          formData.append("profileImg", this.image.raw);
          formData.append("delFile", "");
          HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;

          HTTP.put(`user`, formData, config)
            .then((res) => {
              if (res.data.success) {
                this.getuser();
                this.$message({
                  message: "บันทึกข้อมูลสำเร็จ",
                  type: "success",
                  duration: 4000,
                });
              } else {
                this.alertFailError();
              }
            })
            .catch((e) => {
              this.alertCatchError(e);
              console.log("update user error", e);
            });
        }
      });
    },
    getUrlImage(image) {
      if (image != null) {
        return URL.createObjectURL(image.raw);
      } else {
        return "";
      }
    },
    uploadImage(file) {
      var checkFile = file.raw.type;
      var res = checkFile.split("/");
      if (res[1] == "jpeg" || res[1] == "png") {
        if (file.size <= 1000000) {
          this.showProfile = false;
          this.image = file;
          this.showDefault = true;
          setTimeout(() => {
            this.showProfile = true;
          }, 100);
        } else {
          this.$message({
            message: "ขนาดไฟล์สูงสุดไม่เกิน 4 MB",
            type: "error",
            duration: 4000,
          });
        }
        // console.log(file.size);
      } else {
        this.$message({
          message: "ไฟล์ที่รองรับคือ .JPEG และ .PNG",
          type: "error",
          duration: 4000,
        });
      }
    },
  },
};
</script>
<style scoped>
.btn-save {
  margin-top: 20px;
  width: 154px;
  margin-left: 15px;
}
.btn-cancle {
  margin-top: 20px;
  width: 154px;
  background: transparent;
  color: #7e828f;
  border: 1px solid #7e828f;
}
</style>